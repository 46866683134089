module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.caliberpestservice.com","noQueryString":true},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Caliber Pest Service","short_name":"Caliber Pest Service","start_url":"/","theme_color":"#fff","background_color":"#fff","display":"standalone","icon":"src/images/lobster512.png","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"c511adc78a28d2f3f74ccefc7a0083f1"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager-timeout/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5GTFLLC","includeInDevelopment":false,"timeout":5000,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","exclude":["/dev-404-page/","/404/","/404.html","/offline-plugin-app-shell-fallback/"],"crumbLabelUpdates":[{"pathname":"/blog","crumbLabel":"Blog"},{"pathname":"/pest-library","crumbLabel":"Pest Library"},{"pathname":"/where-we-service","crumbLabel":"Where We Service"}]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-70},
    },{
      plugin: require('../../gatsby-theme-katahdin/gatsby-browser.js'),
      options: {"plugins":[],"projectId":"b921477d-dfe5-0055-71ce-f513533fdefc","authorizationKey":"ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogImZkMTE0OGM0Yzc5MjQxMTFhZTE5NmY0OGFjNmM4ZmUzIiwNCiAgImlhdCI6ICIxNjU2MDAxOTY4IiwNCiAgImV4cCI6ICIyMDAxNjAxOTY4IiwNCiAgInZlciI6ICIxLjAuMCIsDQogICJwcm9qZWN0X2lkIjogImI5MjE0NzdkZGZlNTAwNTU3MWNlZjUxMzUzM2ZkZWZjIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.JW5CcqABVb4vutlGExay_Ac6pdymwQGc42yWjBWQQzk","usePreviewUrl":"false","whereWeServicePathName":"where-we-service","whereWeServiceBreadcrumb":"Where We Service","wWSActive":"0","siteUrl":"https://www.caliberpestservice.com","title":"Caliber Pest Service","description":"Caliber Pest Service","domainVerification":"7DnlJ-Ee7Z5G0N6-BvRnWCAsH1_OLvpX-7bgRTI-Vp8","gtmId":"GTM-5GTFLLC","compassID":"08f944422882507edb70331f4d19af59a7f4abf7e3666c453f9d809ee1862289","recaptchaSiteKey":"6Le3U5YgAAAAAEf-m8mbJEP-RBPjjmaQTSQ6bxkt","recaptchaSecretKey":"6Le3U5YgAAAAALHKwUzIinStFZtpsjBY_VB3erXE","pestLibraryPathName":"pest-library","pestLibraryBreadcrumb":"Pest Library","pestLibraryActive":"1"},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
